import React from 'react';

const HomePage = () => {
  return (
    <>
     <div className='homepage-img-wrp'>
       <img src='./images/banner.png' alt='images'/>
       <div className='homepage-dsc'>
         <h1>শীঘ্রই আসছে</h1>
         <span>দেশের প্রথম নিরাপদ এবং দ্রুতগতির বাংলা ব্রাউজার</span>
       </div>
     </div>
    </>
  )
}

export default HomePage
