import {
  BrowserRouter, Route,
  Switch
} from 'react-router-dom';
// We will create these two pages in a moment
import './App.css';
import CountryListPage from './pages/CountryListPage';
import DataPackApp16Page from "./pages/DataPackApp16Page";
import DataPackApp4Page from "./pages/DataPackApp4Page";
import DataPackErrorPage from "./pages/DataPackErrorPage";
import DataPackListPage from "./pages/DataPackListPage";
import DataPackOTPVerify from "./pages/DataPackOTPVerify";
import DataPackSAPPage from "./pages/DataPackSAPPage";
import DataPackSuccessPage from "./pages/DataPackSuccessPage";
import EmergencyPage from './pages/EmergencyPage';
import ScrollableTabsButtonAuto from "./pages/emergeny-link";
import HomePage1 from "./pages/HomePage (copy)";
import PushIdPage from './pages/PushIdPage';
import PushPage from './pages/PushPage';
import TvPage from './pages/TvPage';

import QuizHomePage from "./pages/quiz/QuizHomePage";
import QuizOtpVerify from "./pages/quiz/QuizOtpVerify";
import Winner from "./pages/quiz2/common/Winner";
import Data from "./pages/quiz2/Data";
import Fail from "./pages/quiz2/Fail";

import Audio from "./pages/Audio";
import AudioDetails from "./pages/AudioDetails";
import Movie from "./pages/Movie";
import MovieDetails from "./pages/MovieDetails";
import News from "./pages/News";
import FunctionalComponent from "./pages/quiz/FunctionalComponent";
import Quote from "./pages/Quote";
import SingleRadioPage from "./pages/SingleRadioPage";
import Weather from "./pages/Weather";

import BlogFunctionalComponent from "./api/BlogFunctionalComponent";
import NoPageFound from './pages/404';
import DurontoDownlode from "./pages/DurontoDownlode";
import HomePage from './pages/HomePage';
import QuizStart from './pages/quiz3/QuizHomePage';
import QuizLife from './pages/quiz4/QuizHomePage';
import QuizHome from "./pages/QuizHome";
import Radio from './pages/Radio';
import Youtube from './pages/Youtube';
import Torjoni from './pages/torjoni';
import Privacy from './pages/privacy';
import FairUsage from './pages/fair-usage-policy';
import Disclamer from './pages/disclamer';
import About from './pages/about';
import Index from './pages/index';


function App() {
  
  return (
      <BrowserRouter>
          <div>
            <div className="content">
              <Switch>
                  <Route exact path="/" component={HomePage} />
                  {/* <Route exact path="/" component={Index} /> */}
                  <Route exact path="/index" component={Index} />
                  <Route exact path="/h" component={HomePage1} />
                  <Route path="/radio" component={Radio} />
                  <Route path="/single_radio" component={SingleRadioPage} />
                  <Route path="/tv" component={TvPage} />
                  <Route path="/youtube" component={Youtube} />
                  <Route path="/news/2021" component={News} />
                  <Route path="/movie" component={Movie} />
                  <Route path="/weather" component={Weather} />
                  <Route path="/quote" component={Quote} />
                  <Route path="/movie_details/:movie_id/:movie_name" component={MovieDetails} />
                  <Route path="/audio" component={Audio}/>
                  <Route path="/audio_details" component={AudioDetails}/>

                  <Route path="/emergenylink" component={EmergencyPage} />
                  <Route path="/emergeny-link" component={ScrollableTabsButtonAuto} />
                  <Route path="/push_id/:push_id" component={PushIdPage} />
                  <Route path="/content_id/:content_id" component={PushPage} />

                  <Route path="/countryList" component={CountryListPage} />

                  <Route path="/data_pack" component={DataPackListPage}/>
                  <Route path="/data_pack_app4" component={DataPackApp4Page}/>
                  <Route path="/data_pack_app16" component={DataPackApp16Page}/>
                  <Route path="/data_pack_sap" component={DataPackSAPPage}/>
                  <Route path="/pack_error" component={DataPackErrorPage}/>
                  <Route path="/pack_success" component={DataPackSuccessPage}/>
                  <Route path="/otp_verify" component={DataPackOTPVerify}/>

                  <Route path="/quiz_home" component={QuizHome}/>

                  <Route path="/hard_quiz" component={QuizHomePage}/>
                  <Route path="/quiz_otp_verify" component={QuizOtpVerify}/>
                  <Route path="/quiz_question" component={Data}/>
                  <Route path="/quiz_winner" component={Winner}/>
                  <Route path="/quiz_fail" component={Fail}/>


                  <Route path="/torjoni" component={Torjoni}/>
                  <Route path="/privacy" component={Privacy}/>
                  <Route path="/fair-usage-policy" component={FairUsage}/>
                  <Route path="/disclamer" component={Disclamer}/>
                  <Route path="/about" component={About}/>


                  <Route path="/easy_quiz" component={QuizStart}/>
                  <Route path="/premium_quiz" component={QuizLife}/>

                  <Route exact path="/duronto_download" component={DurontoDownlode}/>



                  <Route path="/functional_component" component={FunctionalComponent}/>
                  <Route path="/blog_functional_component" component={BlogFunctionalComponent}/>

                  <Route path="*" component={NoPageFound}/>
              </Switch>
            </div>
        </div>
      </BrowserRouter>
    
  );
}

export default App;
