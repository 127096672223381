import axios from "axios";
import React, { useEffect, useState } from 'react';
import InfinitScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { Link } from 'react-router-dom';
import 'vimond-replay/index.css';
import '../css/movie.css';
import logo from "../img/logo-Final.png";
import MovieDetails2 from "./MovieDetails2";

const Movie = () => {

    const[all_movie,setAllMovie] = useState([]);
    const[pageSize,setPageSize] = useState(10);
    const[page,setSetPage] = useState(1);
    const[isLoading,setLoading] = useState(false);
    const[movie_id,setMovieId] = useState('');
    const[play_movie,setPlayMovie] = useState([]);
    const[isShowPlayer,setIsShowPlayer] = useState(false);


    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_movie?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response => {
                setAllMovie(response.data.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [page,pageSize])
    

    const sayHello = (e) => {
        //alert(e);
        playMovie(e)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(
                response => response.text()

            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        setAllMovie(all_movie)
    },[all_movie])


    const playMovie = (e) => {
        setMovieId(e)
        var data = JSON.stringify({"movie_id": e});

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_movie_details',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data : data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(response.status === 200){
                    setAllMovie(response.data.data)
                    setIsShowPlayer(true)
                } else {
                    window.location='/'
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const fetchNextUsers = () => {

        setSetPage(page + 1)

        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_movie?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };
        axios(config)
            .then(response => {
                setAllMovie(all_movie.concat(response.data.data));
                isLoading(true);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div>
            {/* =================================log============================= */}
            <div className="dLogo">
                <img src={logo} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}
            {/*<Replay source="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"*/}
            {/*        initialPlaybackProps={{ isPaused: false }}/>*/}

            {/*<Replay*/}
            {/*    source="https://live-technologies-vod.akamaized.net/movies/rangbaz/playlist.m3u8"*/}
            {/*    initialPlaybackProps={{ isPaused: false }}*/}
            {/*>*/}
            {/*    <HlsjsVideoStreamer />*/}
            {/*</Replay>*/}

            <div className="whatsNewWrap robiNewsWrap">

                {isShowPlayer ?
                    <MovieDetails2 movie_id={movie_id} play_movie={play_movie}/>
                    :<p></p>
                }

                <div className="container">
                    {isShowPlayer ?
                        <div>
                            <img src={play_movie.banner_image} className="img-fluid rounded-circle single_movie_title_img" alt=""/>
                            <span className="single_movie_title">{play_movie.title_bn}</span><br/>
                            <span className="single_movie_views">{play_movie.content_hit_count} views |
                                {play_movie.content_date}</span>
                        </div>
                        :<p></p>
                    }
                    <h2>
                        { isShowPlayer ? 'Related Movie': 'Movie List'}</h2>
                    <div className="">

                        <InfinitScroll
                            dataLength = {all_movie.length}
                            next = {fetchNextUsers}
                            hasMore = {true}
                            loader={<h4></h4>}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                        >

                            {
                                isLoading ?
                                    <div className="loader">
                                        <Loader
                                            type="Circles"
                                            color="#00897A"
                                            height={50}
                                            width={100}
                                            // timeout={1000} //3 secs
                                        />
                                    </div>
                                    :
                                    all_movie.map(cat =>
                                        movie_id === cat.id ? ''
                                            :
                                        <div className="news24Item" key={cat.id}>
                                            <Link  onClick={(e)=>(sayHello(cat.id))}
                                               className="movieItemInner">
                                                <div className="d-flex align-items-center itemInnerWrap">
                                                    <div className="itemImg">
                                                        <img src={cat.banner_image} className="img-fluid"
                                                             alt="img"/>
                                                    </div>
                                                    <div className="info">
                                                        <div className="title">
                                                            {cat.title_bn}
                                                        </div>
                                                        <p>{cat.client_name}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                    )

                            }
                        </InfinitScroll>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Movie;