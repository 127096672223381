import axios from "axios";
import React, { useEffect, useState } from 'react';
import InfinitScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { Replay } from 'vimond-replay';
import HlsjsVideoStreamer from 'vimond-replay/video-streamer/hlsjs';
import '../css/movie.css';

const MovieDetails = (props) => {

    const[movie,setMovie] = useState([]);
    const[all_movie,setAllMovie] = useState([]);
    const[pageSize,setPageSize] = useState(10);
    const[page,setPage] = useState(1);
    const[isLoading,setLoading] = useState(true);

    useEffect(() => {
        relatedMovies();
        playMovie();
    },[])

    const playMovie = () => {
        var data = JSON.stringify({"movie_id": props.match.params.movie_id});

        var config = {
            method: 'post',
            url: 'http://localhost:7782/api/web/v1/get_movie_details',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data : data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(response.status === 200){
                    setMovie(response.data.data)
                } else {
                    window.location='/'
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const relatedMovies = () => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_movie?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                setAllMovie(response.data.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        setAllMovie(all_movie)
    },[all_movie])

    const fetchNextUsers = () => {
        setPage(page + 1)

        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_movie?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: { }
        };
        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                setAllMovie(all_movie.concat(response.data.data))
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div>
            <Replay
                source={movie.link}
                initialPlaybackProps={{ isPaused: false }}
            >
                <HlsjsVideoStreamer />
            </Replay>

            <span className="single_movie_title">{movie.title}</span><br/>
            <span className="single_movie_views">{movie.content_hit_count} views <br/>
                {movie.content_date}</span><br/>
            <span className="related_videos">
                Related Videos
                <hr/>
            </span>


            <div className="whatsNewWrap robiNewsWrap">
                <div className="container">

                    <h2>Movie List</h2>
                    <div className="">

                        <InfinitScroll
                            dataLength = {all_movie.length}
                            next = {fetchNextUsers}
                            hasMore = {true}
                            loader={<h4></h4>}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                        >

                            {
                                isLoading ?
                                    <div className="loader">
                                        <Loader
                                            type="Circles"
                                            color="#dc1c2c"
                                            height={50}
                                            width={100}
                                            // timeout={1000} //3 secs
                                        />
                                    </div>
                                    :
                                    all_movie.map(cat =>
                                        <div className="news24Item">
                                            <Link  to={{
                                                pathname: `/movie_details/${cat.id}/${cat.title_bn}`
                                            }}
                                                   className="robiNewsWrapItemInner">
                                                <div className="d-flex align-items-center itemInnerWrap">
                                                    <div className="itemImg">
                                                        <img src={cat.banner_image} className="img-fluid"
                                                             alt="img"/>
                                                    </div>
                                                    <div className="info">
                                                        <div className="title">
                                                            {cat.title_bn.substring(0, 33)}...
                                                        </div>
                                                        <p>{cat.client_name}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )

                            }
                        </InfinitScroll>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MovieDetails;