import React from 'react';

import axios from 'axios';

import '../css/footer.css';
import '../css/header.css';
import '../css/radio.css';

import { useEffect, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Loader from "react-loader-spinner";
import ReactPlayer from 'react-player';
import logo from '../img/logo-Final.png';


const Radio = () => {

    const[persons,setPersons] = useState([]);
    const[radio,setRadio] = useState([]);
    const[itemVal,setItemVal] = useState([]);
    const[close,setClose] = useState(true);
    const[playLink, setplayLink] = useState('')
    const[isHls,setIsHls] = useState('');
    const[playListLocal,setPlayListLocal] = useState('');
    const[isLoading,setIsLoading] = useState(false);
    const[country,setCountry] = useState('');

    useEffect(() => {
        setIsLoading(true)

        var config = {
            method: 'get',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/get_country_by_flag',
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };
        axios(config)
            .then(res => {
                setPersons(res.data.data);
                setIsLoading(false);
            })
    },[setIsLoading])


    const closeOpen = () => {
        setClose(!close)
    }


    const itemValue = (v) => {
        console.log(v)
        setItemVal(v)
        setIsLoading(true)
        const country_name = v.country;
        const data = JSON.stringify({"country": `${country_name}`});
        //var data = JSON.stringify({"country": "Bangladesh"});

        const config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/get_radio',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data: data
        };

        axios(config)
            .then(res => {
                setCountry(country_name)
                setRadio(res.data.data)
                setIsLoading(false)
            })


    }


    const handleChange = (event) => {

        this.setState({value: event.target.value});
        var country_name = event.target.value;
        var data = JSON.stringify({"country": `${country_name}`});
        //var data = JSON.stringify({"country": "Bangladesh"});

        var config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/get_radio',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log(res.data.data)
                setCountry(country_name)
                setRadio(res.data.data)
            })
    };


    const getPlayLink = (playLink) => {
        const link = playLink;
        const extension = link.substring(link.lastIndexOf(".") + 1);
        //alert(playLink);
        if(extension === 'm3u8'){
            setIsHls('hls')
        } else {
            setIsHls('other')
        }
        setplayLink(playLink)
        localStorage.setItem('playLink', playLink);
        console.log(playLink, "playLink_playLink")
    }


    var player;
    if(isHls === 'hls'){
         player = <ReactPlayer
            url={playLink}
            loop
            autoPlay
            controls
            config={{
                file: {
                    forceHLS: true,
                }
            }}
            width='100%'
            height='10%'
            className={'player'}
        />;
    } else if(isHls === 'other') {
         player = <ReactAudioPlayer
            src={playLink}
            loop
            autoPlay
            controls
            className={'player'}
        />;
    } else {
        const player = "";
    }
    return (
        <div>

            {/* =================================log============================= */}
            <div className="dLogo">
                <img src={logo} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}

            <div className={'dropdown_list'}>

                <div className="dropdown mb-4 border from-control">
                    <div className={'toggle'} onClick={() => (closeOpen())}>

                        {itemVal.country ?
                            <>
                                <img src={itemVal.flag} alt="flag"
                                     style={{width: '20px', height: '20px'}}/>
                                &nbsp;
                                {/*{itemVal.country}*/}
                            </>
                            :
                            'Select country'
                        }


                    </div>

                    <div className={close ? 'dropdown-menu' : 'dropdown-menu show'}>

                        {isLoading  && <div className={'isLoading'}><i className="fa fa-circle-o-notch"></i></div>  }

                        <ul className={"countryList"}>
                            {persons.map((item, i) => {
                                return (
                                    // <li onClick={(e)=> this.handleChange(item) } key={i}>
                                    <li onClick={(e) => {itemValue(item); closeOpen()}} key={i}>
                                        <img src={item.flag} alt="flag" style={{width: '20px', height: '20px'}}/>
                                        {/*&nbsp;*/}
                                        {/*&nbsp;*/}
                                        {/*{item.country}*/}
                                    </li>
                                )
                            })}
                        </ul>


                    </div>

                </div>

                {/* rdaio_data */}

                <div className="mbContainer">
                    <div className="text-center">
                         <h2>{country}</h2>
                        <div className="row cusRow">
                            {isLoading  && <div className={'isLoading'}>
                                {/*<i className="fa fa-circle-o-notch"></i>*/}
                                <div className="loader">
                                    <Loader
                                        type="Circles"
                                        color="#00897A"
                                        height={50}
                                        width={100}
                                    />
                                </div>
                            </div>  }

                            {
                                radio.map((cat, i) =>
                                <div className="text-center singleTv col" key={i}>
                                    <a onClick={(e) => (getPlayLink(cat.link))}>
                                        <div className="itemImg">
                                            <img src={cat.banner_image} className="img-fluid"
                                                 alt="img"/>
                                        </div>
                                        <div className="title">
                                            {cat.title_bn}
                                        </div>

                                    </a>
                                </div>
                            )

                            }
                        </div>
                    </div>
                </div>
                {player}

                {/* end_radio_data */}
            </div>
        </div>
    );
}

export default Radio;