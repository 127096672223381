import axios from 'axios';
import React from 'react';

import logo from '../img/Fab.png';
import footerImg from '../img/powered-by.png';

import { useState } from 'react';
import DatapackBuy from "../common/DatapackBuy";
import '../css/footer.css';
import '../css/header.css';
import '../css/otp.css';

const DataPackOTPVerify = () => {

    const[otp,setOtp] = useState('');
    const[otp_msg,setOtpMessage] = useState('');

    const handleChange = (event) => {
        var newValue = event.target.value;
        setOtp(newValue)
    }

    const onSubmit = (event) => {
        event.preventDefault()
        const msisdn = localStorage.getItem('msisdn');
        const pack = localStorage.getItem('pack');

        var data = JSON.stringify({"msisdn":msisdn,"secret_key":otp});
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp/verification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data : data
        };

        axios(config)
        .then(res => {
            if(res.data.success){
                const datapack_buy = DatapackBuy.dataPackBuyAPi(msisdn,pack);
                // setState({
                //     otp_msg: 'success',
                // });
            } else {
                setOtpMessage('Invalid OTP code');
            }
        })
    }

    const resendOTP = () => {
        const msisdn = localStorage.getItem('msisdn');
        const all_otp = otp.getOTP(msisdn);
    }

    const msisdn = localStorage.getItem('msisdn');
    const pack = localStorage.getItem('pack');
    return (
        <div>
            {/* =================================log============================= */}
            <div className="otpheader">
                <img src={logo} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}
            <div className="col-lg-12 text-center pt-5">
                <h3>OTP Verification</h3>
                <p>OTP Sent to: <b>{msisdn}</b><br/>
                    Selected Pack: <b>{pack}</b></p>
            </div>
            {/*form*/}
            <form className="p-2" onSubmit={onSubmit}>
                <div className="col-lg-12 text-center">
                    <span className="text-danger text-">{otp_msg}</span>
                    {/*<input id="partitioned" type="text" maxLength="5"/>*/}
                    <input type="text" name="otp" value={otp} className="form-control" onChange={handleChange} placeholder="Enter OTP..." maxLength="6" autoComplete="true"  required/>
                    <p>Didn't receive the OTP? <a href="" onClick={resendOTP}>RESEND OTP</a></p>
                    <button className="btn btn-block btn-md btn-success"><b>Verify OTP</b></button>
                </div>
            </form>
            {/*form*/}
            {/*===================================powered_buy=========================*/}
            <div className="footerImg text-center">
                <img src={footerImg} alt="robi" />
            </div>
            {/*===================================end_powered_buy======================*/}
        </div>
    );
}

export default DataPackOTPVerify;