import axios from "axios";
import React, { useEffect, useState } from 'react';
import InfinitScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import '../css/quote.css';
import logo from "../img/logo-Final.png";

const Quote = () => {
    const[all_quote,setAllQuote] = useState([]);
    const[pageSize,setPageSize] = useState(10);
    const[page,setPage] = useState(1);
    const[isLoading,setLoading] = useState(true);


    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_quote?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                setAllQuote(response.data.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    },[])


    const sayHello = (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(
                response => response.text()

            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        setAllQuote(all_quote)
    },[all_quote]);



    const fetchNextUsers = () => {
        setPage(page + 1)

        var config2 = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_quote?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config2)
            .then(response=> {
                setAllQuote(response.data.data)
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div>
            {/* =================================log============================= */}
            <div className="dLogo">
                <img src={logo} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}

            {/* =================================today_quote====================== */}
            <div className="container">
                <h2>Today's Quote</h2>
                <InfinitScroll
                    dataLength = {all_quote.length}
                    next = {fetchNextUsers}
                    hasMore = {true}
                    loader={<h4></h4>}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >

                    {
                        isLoading ?
                            <div className="loader">
                                <Loader
                                    type="Circles"
                                    color="#00897A"
                                    height={50}
                                    width={100}
                                />
                            </div>
                            :
                            all_quote.map(cat =>
                                <div className="quoteItem" key={cat.id}>
                                    <a rel="noreferrer" target='_blank' href={cat.link}  onClick={(e)=>(sayHello(cat.id))}
                                           className="quItemInner">
                                        <div className="d-flex align-items-center quoitemInnerWrap">
                                            <div className="info">
                                                <div className="qtitle">
                                                    {cat.description}
                                                </div>
                                                <p>--{cat.title}</p>
                                                <p className="views">
                                                    <i className="fa fa-clock-o" aria-hidden="true"></i> &nbsp;
                                                    {cat.content_hit_count} views
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            )

                    }
                </InfinitScroll>
            </div>
            {/* =================================end_today_quote================== */}
        </div>
    );
    
}

export default Quote;