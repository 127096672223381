import axios from "axios";
import React, { useEffect, useState } from 'react';
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import '../../css/quiz.css';
import Main from "./Main";


const Data = (props) =>{
    
    const[items,setItems] = useState([]);
    
    useEffect(() => {
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/quiz/hard/v3/get_quiz',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response => {
                // this.setState({
                //     items: response.data.data
                // })
                const decodeData = Buffer.from(response.data, 'base64');
                const afterModify = JSON.parse(JSON.stringify(JSON.parse(decodeData).data));
                setItems(afterModify)
            })
            .catch(function (error) {
                console.log(error);
            });
    })

    if(props.fromHomePage){
        return <Redirect to="hard_quiz" />
    }
    return (
        <div>
            {   items.length > 0 ? <Main data={items}/> :
                <div className="loader">
                    <Loader
                        type="Circles"
                        color="#dc1c2c"
                        height={50}
                        width={100}
                        timeout={1000} //3 secs
                    />
                </div>
            }
        </div>
    );
    
}

export default Data;