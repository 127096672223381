import axios from "axios";
import React, { useEffect, useState } from 'react';
import InfinitScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import logo from "../img/logo-Final.png";
import AudioDetails from "./AudioDetails";

const Audio = () => {

    const[all_audio,setAllAudio] = useState([]);
    const[pageSize,setPageSize] = useState(10);
    const[audioLink,setAudioLink] = useState('');
    const[audioShow,setAudioShow] = useState(false);
    const[page,setPage] = useState(1);
    const[isLoading,setLoading] = useState(true);

    const sayHello = (id,link) => {

        playAudio(link)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${id}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));


    }

    const playAudio = (e) => {
        setAudioLink(e)
        setAudioShow(true)
    }

    useEffect(() => {
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_audio?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                setAllAudio(response.data.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    },[])

    useEffect(() => {
        setAllAudio(all_audio)
    },[all_audio])
    
    const fetchNextUsers = () => {
        setPage(page + 1)

        var config2 = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_audio?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config2)
            .then(response => {
                console.log(JSON.stringify(response.data));
                setAllAudio(all_audio.concat(response.data.data))
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    return (
        <div>
            {/* =================================log============================= */}
            <div className="dLogo">
                <img src={logo} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}

            {/* =================================audio_player===================== */}
            <div className="whatsNewWrap robiNewsWrap">
                <div className="container">
                    <div className="">

                    <InfinitScroll
                        dataLength = {all_audio.length}
                        next = {fetchNextUsers}
                        hasMore = {true}
                        loader={<h4></h4>}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >

                        {
                            isLoading ?
                                <div className="loader">
                                    <Loader
                                        type="Circles"
                                        color="#00897A"
                                        height={50}
                                        width={100}
                                        // timeout={1000} //3 secs
                                    />
                                </div>
                                :
                                all_audio.map(cat =>
                                    <div className="news24Item" key={cat.id}>
                                        <Link onClick={(e)=>(sayHello(cat.id,cat.link))} className="movieItemInner">
                                            <div className="d-flex align-items-center itemInnerWrap">
                                                <div className="itemImg">
                                                    <img src={cat.banner_image} className="img-fluid"
                                                         alt="img"/>
                                                </div>
                                                <div className="info">
                                                    <div className="title">
                                                        {cat.title_bn.substring(0, 33)}
                                                    </div>
                                                    <p>{cat.description_bn}</p>
                                                </div>
                                            </div>
                                            <div className="shareIcon">
                                                  <span>
                                                          <i className="fa fa-heart fa-1x fa-beat"></i>
                                                  </span>
                                            </div>
                                        </Link>
                                    </div>

                                )

                        }
                    </InfinitScroll>
                </div>
                </div>
            </div>
            {
                audioShow ?
                <AudioDetails audioLink={audioLink}/>: <p></p>
            }
            {/* =================================end_audio_player================= */}
        </div>
    );
}

export default Audio;