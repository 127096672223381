import React, { Component } from 'react';
import Loader from "react-loader-spinner";

class NotMobileView extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true
        }
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 200);
    }
    render() {
        return (
            <div>
                {this.state.isLoading ?

                    <div className="loader">
                        <Loader
                            type="Circles"
                            color="#00897A"
                            height={50}
                            width={100}
                            //timeout={1000} //3 secs
                        />
                    </div> :
                    <div>
                        {/*<p>{this.props.browserName}</p>*/}
                        <center style={{fontSize: "22px", fontWeight: "600", paddingTop: "34px"}}>কুইজ খেলতে হলে <a href="https://duronto.com.bd/" style={{color: "#00897A"}}>দুরন্ত ব্রাউজার </a> ব্যবহার করুন।</center>
                    </div>
                }
            </div>
        );
    }
}

export default NotMobileView;