import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import '../css/footer.css';
import '../css/header.css';
import '../css/home.css';

const EmergencyPage = () => {
    
    const[all_links,setAllLinks] = useState([]);
    const[isLoading,setLoading] = useState(true);


    useEffect(() => {
        var config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/get_emergency_link',
            headers: { 
              'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
          };
          
          axios(config)
          .then(res => {
                setLoading(false)
                setAllLinks(res.data.data)
          })
    },[])


    const handleQueryChange = (query) => {

        var data = JSON.stringify({"district":query});

        var config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/search_by_location',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(res => {
              setLoading(false)
              setAllLinks(res.data.data)
          })

    }

    if(isLoading){
        return <div>Loading...</div>
    } else {

        return (
            <div>
                {/* search bar */}
                <input
                    onChange={({ target: { value } }) => handleQueryChange(value)}
                />
                {/* end search bar */}
                <Tabs defaultActiveKey="Ambulance" id="uncontrolled-tab-example">
                    {
                        all_links.map((cat,key) => {
                        return <Tab eventKey={cat.category} title={cat.category_bn} key={cat.id}>
                                    {cat.link.map(item =>
                                        <p key={item.id}>{item.emergency_helpline_bn}   {item.phone_bn}</p>
                                    )}
                                </Tab>
                        })
                    }
                </Tabs>
            </div>
        );

    }
}

export default EmergencyPage;