import React from 'react'

export const Logo = () => {
  return (
    <>
     <div className='logo'>
        <a href='/'><img src='./images/logo.png' alt='' /></a>
     </div>
    </>
  )
}
