import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Image, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import '../css/footer.css';
import '../css/header.css';
import '../css/radio.css';

const CountryListPage = () => {

    const[countrylist,setCountryList] = useState([]);

    useEffect(() => {
        var config = {
            method: 'get',
            url: 'http://103.4.145.125:7781/api/web/v1/get_country_by_flag',
            headers: { 
              'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
          };
        axios(config)
        .then(res => {
            setCountryList(res.data.data)
        })
    }, [])
    


    return (
        <div>
            {/* top_bar */}
            <div className="topBar">
                <span className='topBarInner'>
                    <span className='title'>Country list
                    </span>
                    <Link to="/" className="homeIcon">
                        <i className="fa fa-home"></i>
                    </Link>
                </span>
            </div>
            {/* end_top_bar */}

            <ListGroup>
                { countrylist && countrylist.map((e, key) => {
                    return <ListGroup.Item key={key}>
                                <Image src={e.flag} className="rounded flag" />
                                <span style={{padding: "15px"}}>{e.country}</span>
                           </ListGroup.Item>
                })}
            </ListGroup>
        </div>
    );
   
}

export default CountryListPage;