import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
    Link
} from 'react-router-dom';

import '../css/footer.css';
import '../css/header.css';
import '../css/home.css';



const DataPackSuccessPage = () => {
    return (

        <div className="success_img">
            <Container>
                <Row>
                    <Col sm={12}>You Have Succesfully Purchased Data Pack.</Col>
                </Row>
                <Row>
                    <Col>
                        <Link to="/">
                            <i className="fa fa-home"></i>Back Home
                        </Link>
                    </Col>
                </Row>
            </Container>
            {/* <div >

                    <br />
                        <span style={{fontSize:"20px"}}>You have successfully purchased.</span>
                    <br />

                    <div className="" style={{fontSize:"20px"}}>
                        <Link to="/">
                            <i className="fa fa-home"></i>Back Home
                        </Link>
                    </div>
                

            </div> */}
        </div>
    );
}

export default DataPackSuccessPage;