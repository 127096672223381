import axios from "axios";
import React, { useEffect, useState } from 'react';
import InfinitScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import logo from "../img/logo-Final.png";

const Youtube = () => {

    const[all_youtube,setYoutubeContent] = useState([]);
    const[pageSize,setPageSize] = useState(5);
    const[page,setPage] = useState(1);
    const[isLoading,setLoading] = useState(false);


    useEffect(() => {
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_youtube?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response=> {
                setYoutubeContent(response.data.data)
                setLoading(true)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [page,pageSize])
    
    
    const sayHello = (e) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(
                response => response.text(),
                
            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }


    useEffect((prevProps, prevState) => {
        setYoutubeContent(all_youtube)
    }, [all_youtube])
    

    const fetchNextUsers = () => {
        setPage(page + 1)

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_youtube?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response=> {
                
                setYoutubeContent(response.data.data)
                setLoading(true)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    return (
        <div className="container">
            {/* =================================log============================= */}
            <div className="dLogo">
                <img src={logo} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}

            {/*  =================================youtube_content=================  */}
            <InfinitScroll
                dataLength = {all_youtube.length}
                next = {fetchNextUsers}
                hasMore = {true}
                loader={<h4></h4>}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            >
            {
                !isLoading ?
                    <div className="loader">
                        <Loader
                            type="Circles"
                            color="#00897A"
                            height={50}
                            width={100}
                        />
                    </div>
                    :
                    all_youtube.map(cat =>
                        <div className="whatsNewItem cusCol" key={cat.id}>
                            <a href={cat.link} onClick={(e) => (sayHello(cat.id))} className="whatsNewItemInner">
                                <div className="itemImg">
                                    <img src={cat.banner_image} className="img-fluid"
                                         alt="Logo"/></div>
                                <div className="info">
                                    <div className="title">
                                        {cat.title_bn}
                                        <p><i className="fa fa-clock-o" aria-hidden="true"></i> &nbsp;
                                            {cat.content_date}</p>
                                    </div>
                                    {/*<div className="exPlore d-flex justify-content-between align-items-center">*/}
                                    {/*                      <span>*/}
                                    {/*                          <span className="linked">Explore*/}
                                    {/*                              <i className="fa fa-angle-right"></i>*/}
                                    {/*                          </span>*/}
                                    {/*                      </span>*/}
                                    {/*    <span>*/}
                                    {/*        <i className="fa fa-share-alt"></i>*/}
                                    {/*    </span>*/}
                                    {/*</div>*/}
                                </div>
                            </a>
                        </div>
                    )
            }
            </InfinitScroll>
            {/*  =================================end_youtube_content=================  */}

        </div>
    );
    
}

export default Youtube;