import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DatapackBuy from "../common/DatapackBuy";
import otp from "../common/otp";

import logo from '../img/logo-Final.png';
import footerImg from '../img/powered-by.png';

const DataPackApp4Page = () => {

    const[msisdn,setMsisdn] = useState('');
    const[msisdn1,setMsisdn1] = useState('');
    const[is_otp,setIsOtp] = useState(false);

    useEffect(() => {
        var config = {
            method: 'get',
            url: 'http://fun-world.mobi/get-msisdn.php',
          };
          
          axios(config)
          .then(res => {
            if(res.data == 'No MSISDN Found!'){
                setMsisdn('')
                setIsOtp(true)
            } else {
                setMsisdn(res.data)
                setIsOtp(false)
            }
          })
    })

    const handleChange = (event) => {

        var newIteam = event.target.name; 
        var newValue = event.target.value;
        setMsisdn1(newValue)
    
    }

    const handlePackBuyApp4 = (event) => {
        event.preventDefault();


        if(is_otp){
            const all_otp = otp.getOTP(msisdn1);

            localStorage.setItem('msisdn', msisdn1);
            localStorage.setItem('pack', "APP_4");
            window.location = '/otp_verify';
        }
        else
        {
            const datapack_buy = DatapackBuy.dataPackBuyAPi(msisdn,'APP_4');
        }

    }



    var msisdn_form;
    if(msisdn){
        msisdn_form = "";

    } else {
        msisdn_form = <input type="text" name="msisdn1" value={msisdn1} className="form-control" onChange={handleChange} placeholder="Enter Mobile Number..." required/>;
    }

    return (
        <div>
            {/*================================== top_bar ================================*/}
            <div className="topBar">
                <span className='topBarInner'>
                    <Link to="/data_pack" className="">
                        <i className="fa fa-arrow-left"></i>
                        &nbsp; Purchase Confirmation
                    </Link>
                </span>
            </div>
            {/*===================================== end_top_bar ============================*/}

            {/* =================================log============================= */}
            <div className="dLogo">
                <img src={logo} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}

            {/*=================================== Pack Details ==================*/}
            <ListGroup>
                <ListGroup.Item><b className="pr-5">Pack Name:</b>              <span className="float-right">APP_4</span></ListGroup.Item>
                <ListGroup.Item><b className="pr-5">Volume:  </b>               <span className="float-right">1.2 GB</span></ListGroup.Item>
                <ListGroup.Item><b className="pr-5">Validity: </b>              <span className="float-right">3 Days</span></ListGroup.Item>
                <ListGroup.Item><b className="pr-5">Price:   </b>               <span className="float-right">35 Tk</span></ListGroup.Item>
                <br></br>
                {/* form */}
                <form className="p-2" onSubmit={handlePackBuyApp4}>
                    <p>Purchasing for: {msisdn}</p>
                    <p>
                        {msisdn_form}
                    </p>
                    <button className="btn btn-block btn-md btn-success btn_custom"><b>Buy</b></button>
                </form>
                {/* end_form */}
           </ListGroup>
           {/*==================================== end Pack Details ===============*/}

            <div className="footerImg text-center">
                <img src={footerImg} alt="robi" />
            </div>

        </div>
    );
}

export default DataPackApp4Page;