import React from 'react';
import { Header } from '../components/Header/Header';

const Torjoni = () => {
  return (
    <>
     <div className='block-1280'>
       <Header />
     </div>
    </>
  )
}

export default Torjoni
