import React from 'react';
import logo from "../img/logo-Final.png";

const SingleRadioPage = () => {
    return (
        <div>
            {/* =================================log============================= */}
            <div className="dLogo">
                <img src={logo} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}
        </div>
    );
}

export default SingleRadioPage;