import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import unhappyImg from "../../img/un-happy.svg";
import nextclick from "../../sound/slider.wav";
import winnerSound from '../../sound/winner.wav';

const Fail = (props) => {

    useEffect(() => {
        new Audio(winnerSound).play();
    })
    

    const resendHome = () => {
        new Audio(nextclick).play();
        //window.location.reload(false);
        window.location = '/quiz_home';
    }
    const lang = localStorage.getItem('lang');
    return (
        <div>
            {/* =================================log============================= */}
            <div className="failHeader">
                <center>{lang === 'english' ? 'Duronto Quiz Result' : 'দুরন্ত কুইজের ফলাফল'}</center>
            </div>
            <div className="quizWinner">
                <img src={unhappyImg} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}
            <div className="text-center p-2">
                <h5>{lang === 'english' ? 'Not all of your questions were answered correctly.' : 'আপনার সবগুলো প্রশ্নের উত্তর সঠিক হয় নি।'}</h5>
                <h6 className="text-success pt-2">{lang === 'english' ? 'Correct Answer:' : 'সঠিক উত্তর:'} <strong> {props.rightAns} </strong> {lang === 'english' ? '' : 'টি'} </h6>
                <h6 className="text-danger">{lang === 'english' ? 'Wrong answer:' : 'ভুল উত্তর:'} <strong> {props.total - props.rightAns}</strong> {lang === 'english' ? '' : 'টি'} </h6>
                <h6>{lang === 'english' ? 'You got:' : 'আপনি পেয়েছেন:'} <strong> {props.score} </strong> {lang === 'english' ? 'Points' : 'পয়েন্ট'} </h6>
                <h5 className="pt-3">{lang === 'english' ? 'Don\'t be upset! Your points are being added to your Duronto profile, which will help you to get gifts later. ' : 'মন খারাপ করবেন না! আপনার পয়েন্ট গুলো যোগ হয়েছে দুরন্ত প্রফাইলে, যা পরবর্তিতে আপনাকে উপহার পেতে সাহায্য করবে।'}</h5>
                <Link onClick={resendHome} className="btn btn-md duronto_btn2 text-center mt-3">
                    <b><i className="fa fa-refresh" aria-hidden="true"></i> {lang === 'english' ? 'Please try again' : 'আবার চেষ্টা করুন'}</b>
                </Link>

            </div>
        </div>
    );
}

export default Fail;