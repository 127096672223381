import React, { useEffect, useState } from 'react';
import logo from "../img/logo-Final.png";

import axios from 'axios';
import InfinitScroll from 'react-infinite-scroll-component';
import Loader from "react-loader-spinner";

const News = () => {
    
    const[all_news,setAllNews] = useState([]);
    const[pageSize,setPageSize] = useState(10);
    const[page,setPage] = useState(1);
    const[isLoading,setLoading] = useState(true);

    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_news?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        //alert("first:  " + config.url);
        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                setAllNews(response.data.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    },[page,pageSize])

    useEffect(() => {
        setAllNews(all_news)
    },[all_news])



    const sayHello = (e) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(
                response => response.text()

            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    const fetchNextUsers = () => {
        
        setPage((prevState) => ({page : page + 1}))
        //count:per page koita kore dekhabe
        //start:start
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_news?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        //alert("update:  "+ config.url);
        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                setAllNews(all_news.concat(response.data.data))
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div>


            {/* =================================log============================= */}
            <div className="dLogo">
                <img src={logo} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}


            <div className="whatsNewWrap robiNewsWrap">
                <div className="container">

                    <h2>Today News</h2>
                    <div className="">

                        <InfinitScroll
                            dataLength = {all_news.length}
                            next = {fetchNextUsers}
                            hasMore = {true}
                            loader={<h4></h4>}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                        >

                        {
                            isLoading ?
                                <div className="loader">
                                    <Loader
                                        type="Circles"
                                        color="#dc1c2c"
                                        height={50}
                                        width={100}
                                        // timeout={1000} //3 secs
                                    />
                                </div>
                                :
                            all_news.map(cat =>
                            <div className="news24Item">
                                <a href={cat.link} onClick={(e)=>(sayHello(cat.id))}
                                   className="robiNewsWrapItemInner">
                                    <div className="d-flex align-items-center itemInnerWrap">
                                        <div className="itemImg">
                                            <img src={cat.banner_image} className="img-fluid"
                                                 alt="img"/>
                                        </div>
                                        <div className="info">
                                            <div className="title">
                                                {cat.title_bn}
                                            </div>
                                            <p>{cat.client_name}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )

                        }
                        </InfinitScroll>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default News;