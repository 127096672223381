import React from 'react';
import {Link} from 'react-router-dom';

export const Navbar = () => {
  return (
    <>
      <nav className='nav-menu'>
          <ul className='clearfix reset-list'>
            <li>
              <Link to="/">তর্জনী কি এবং কেন?</Link>
            </li>
            <li>
              <Link to="/about">গুরুত্বপূর্ণ ফিচারস</Link>
            </li>
            <li>
              <Link to="/users">ডাউনলোড</Link>
            </li>
          </ul>
      </nav>
    </>
  )
}
