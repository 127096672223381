import React from 'react';

const FairUsage = () => {
  return (
    <>
     <div className='block-414'>
      <div className='page-content-wrp'>
          <strong>গোপনীয়তা এবং নীতি</strong>
          <p>এই পৃষ্ঠাটি কোনও ব্যক্তি আমাদের পরিষেবা ব্যবহার করার সিদ্ধান্ত নিলে ব্যক্তিগত তথ্য সংগ্রহ, ব্যবহার এবং ব্যক্তিগত তথ্য প্রকাশের সাথে আমাদের নীতি সম্পর্কিত তথ্য অবহিত করতে ব্যবহৃত হচ্ছে।</p>
          <p>আপনি যদি আমাদের পরিষেবা ব্যবহার করতে চান, তবে আপনি এই নীতি সম্পর্কিত তথ্য সংগ্রহ এবং ব্যবহারের বিষয়ে সম্মত হচ্ছেন। আমরা যে ব্যক্তিগত তথ্য সংগ্রহ করি তা পরিষেবা সরবরাহ এবং উন্নতির জন্য ব্যবহৃত হয়। আমরা এই গোপনীয়তা নীতি বর্ণিত ব্যতীত অন্য কারও সাথে আপনার তথ্য ব্যবহার বা প্রকাশ করব না।</p>
          <p>এই গোপনীয়তা নীতিতে ব্যবহৃত শর্তাদি, আমাদের শর্তাদি এবং শর্তাবলী হিসাবে একই অর্থ রয়েছে, যা এই গোপনীয়তা নীতিভিন্ন অন্যথায় সংজ্ঞায়িত না করা হলে দুরন্ত ব্যবহারযোগ্য হবে।</p>
          <strong>তথ্য সংগ্রহ এবং ব্যবহার</strong>
          <p>আরও ভাল অভিজ্ঞতার জন্য, আমাদের পরিষেবাটি ব্যবহার করার সময়, আমরা আপনাকে ডিভাইস সনাক্তকরণের মধ্যে সীমাবদ্ধ না রেখে নির্দিষ্ট কিছু ব্যক্তিগতভাবে সনাক্তকরণযোগ্য তথ্য সরবরাহ করতে পারি। আমাদের অনুরোধ করা তথ্য আমাদের দ্বারা ধরে রাখা হবে এবং এই গোপনীয়তা নীতি বর্ণিত হিসাবে ব্যবহৃত হবে।</p>
          <p>অ্যাপটি তৃতীয় পক্ষের পরিষেবাগুলি ব্যবহার করে যা আপনাকে সনাক্ত করনে ব্যবহৃত তথ্য সংগ্রহ করতে পারে।</p>
          <strong>লগ ডেটা</strong>
          <p>আমরা আপনাকে অবহিত করতে চাই যে আপনি যখনই আমাদের পরিষেবা ব্যবহার করেন, অ্যাপটিতে কোনও ত্রুটির ক্ষেত্রে আমরা লগ ডেটা নামে আপনার ফোনের ডেটা এবং তথ্য (তৃতীয় পক্ষের পণ্যগুলির মাধ্যমে) সংগ্রহ করি। এই লগ ডেটাতে আপনার ডিভাইস ইন্টারনেট প্রোটোকল ("আইপি") ঠিকানা, ডিভাইসের নাম, অপারেটিং সিস্টেম সংস্করণ, আমাদের পরিষেবাটি ব্যবহার করার সময় অ্যাপ্লিকেশনটির কনফিগারেশন, পরিষেবাটি আপনার ব্যবহারের সময় এবং তারিখ ও অন্যান্য পরিসংখ্যানের মতো তথ্য অন্তর্ভুক্ত থাকতে পারে ।</p>
          <strong>কুকিজ</strong>
          <p>কুকিজ হ'ল অল্প পরিমাণে ডেটাযুক্ত ফাইল যা সাধারণত বেনামে অনন্য সনাক্তকারী হিসাবে ব্যবহৃত হয়। এগুলি আপনি যে ওয়েবসাইটগুলিতে যান এবং আপনার ডিভাইসের অভ্যন্তরীণ মেমরিতে সঞ্চিত হয় সেগুলি থেকে আপনার ব্রাউজারে প্রেরণ করা হয়।</p>
          <p>এই পরিষেবাটি এই "কুকিজ" স্পষ্টভাবে ব্যবহার করে না। তবে অ্যাপ্লিকেশন তৃতীয় পক্ষের কোড এবং গ্রন্থাগারগুলি(লাইব্রেরি) ব্যবহার করতে পারে যা তথ্য সংগ্রহ এবং তাদের পরিষেবাদি উন্নত করতে "কুকিজ" ব্যবহার করে। আপনার কাছে এই কুকিজগুলি গ্রহণ বা অস্বীকার করার স্বাধীনতা রয়েছে এবং আপনার ডিভাইসে কুকি কখন প্রেরণ করা হচ্ছে তা জানতে পারবেন। জেনে রাখুন, আপনি যদি আমাদের কুকিজগুলি প্রত্যাখ্যান করতে চান তবে আপনি এই পরিষেবার কিছু অংশ ব্যবহার করতে পারবেন না।</p>
        </div>
     </div>
    </>
  )
}

export default FairUsage
