import React from 'react';
import { Logo } from './Logo';
import './Header.css';
import { Navbar } from './Navbar';


export const Header = () => {
  return (
    <>
      <div className='header'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='header-innr'>
                        <Logo />
                        <Navbar />
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}
