import React, { Component } from 'react';
import Loader from "react-loader-spinner";

class NotMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            device: ''
        }

        this.goPlayStore = this.goPlayStore.bind(this);

    }
    componentDidMount() {
        // setTimeout(() => {
        //     this.setState({
        //         isLoading: false
        //     })
        // }, 200);

        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            //return "Windows Phone";
            this.setState({
                device: "windows"
            })
        }

        if (/android/i.test(userAgent)) {
            //return "Android";
            this.setState({
                device: "android"
            })
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            //return "iOS";
            this.setState({
                device: "iphone"
            })
        }

    }

    goPlayStore(device) {
        if(device == "android") {
            window.location.href = "https://play.google.com/store/apps/details?id=com.duronto.lite"
        } else if(device == "iphone") {
            window.location.href= "https://apps.apple.com/us/app/duronto-browser/id1554054216"
        } else {
            window.location.href = "https://play.google.com/store/apps/details?id=com.duronto.lite"
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoading ?

                    <div className="loader">
                        <Loader
                            type="Circles"
                            color="#dc1c2c"
                            height={50}
                            width={100}
                            //timeout={1000} //3 secs
                        />
                    </div> :
                    <div>
                        {/*<p>{this.props.browserName}</p>*/}
                        {/*<p>{this.props.browserVersion}</p>*/}
                        <center style={{fontSize: "22px", fontWeight: "600", paddingTop: "34px"}}>
                            কুইজ খেলতে হলে
                            <a href="http://103.4.145.125:7783/" style={{color: "#00897A"}}> তর্জনী ব্রাউজার </a>
                            ব্যবহার করুন।
                        </center>
                        <br/>
                        <div className="text-center">
                            তর্জনী ব্রাউজার ডাউনলোড করতে এখানে ট্যাপ করুন
                        </div>
                        {/*{this.state.device}*/}
                        <div className="col-12 quizTypeButton d-flex justify-content-center">
                            <button onClick={()=> {
                                this.goPlayStore(this.state.device)
                            }
                            } className="btn btn-md duronto_know_banagladesh_btn">
                                ডাউনলোড তর্জনী ব্রাউজার
                            </button>
                        </div>
                    </div>
                        }
            </div>
        );
    }
}

export default NotMobileView;