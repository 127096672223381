import React, { useEffect, useState } from 'react';

import '../css/footer.css';
import '../css/header.css';
import '../css/home.css';

import logo from '../img/logo-Final.png';

const HomePage = () => {

    const[all_contents,setAllContents] = useState();
    const[isLoading,setIsLoading] = useState(false);

    const sayHello = (e) => {
        //alert(e);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({
            "event_type":"content_click_event",
            
            "event_header":{
              "device_id":"speedDial"
            },
            "event_body":{
              "category_id":"3",
              "category_name":"Speed Dial Service",
              "content_id":`${e}`,
              "content_title":""
            }
          });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v1/torjoini-event-add", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(process.env.REACT_APP_API_URL+"/api/app/v1/get-speed-dials", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                setAllContents(result.data)
                setIsLoading(true)
            })
            .catch(error => console.log('error', error));
    },[])


    console.log(all_contents);

    return (
        <div>
            {/* =================================log============================= */}
            <div className="dLogo">
                <img src={logo} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}


          
                    {/* // ======================speed_dials(11)================== */}

                        
                            <div className="mbContainer">
                                <div className="container text-center">
                                    <div className="row cusRow">

                                        {

                                            all_contents && all_contents.map(cat =>
                                            <div className="text-center singleItem col" key={cat.id}>
                                                <a href={cat.link} onClick={(e)=>(sayHello(cat.id))}>
                                                    <div className="itemImg">
                                                        <img src={cat.banner_image} className="img-fluid"
                                                             alt="img"/>
                                                    </div>
                                                    <div className="title">
                                                        {cat.title_bn}
                                                    </div>

                                                </a>
                                            </div>
                                        )


                                        }

                                    </div>
                                </div>
                            </div>
                        
                    {/* // ======================end speed_dials(11)=============== */}

        </div>
    );
}

export default HomePage;