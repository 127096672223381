import { useEffect, useState } from 'react';

const DurontoDownlode = () => {

    const[device,setDevice] = useState('');

    useEffect(() => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            //return "Windows Phone";
            setDevice('windows')
        }

        if (/android/i.test(userAgent)) {
            //return "Android";
            setDevice('android')
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            //return "iOS";
            setDevice('iphone')
        }
    })
    
    if(device === "android") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.duronto.lite"
    } else if(device === "iphone") {
        window.location.href= "https://apps.apple.com/us/app/duronto-browser/id1554054216"
    } else {
        window.location.href = "https://play.google.com/store/apps/details?id=com.duronto.lite"
    }
    return (
        <></> 
    )
}

export default DurontoDownlode;