import React from 'react';
import { Link } from "react-router-dom";

import '../../../css/header.css';
import '../../../css/home.css';
import '../../../css/quiz.css';

import { useEffect } from 'react';
import winnerImg from "../../../img/winner.gif";
import winnerSound from "../../../sound/winner.wav";

const Winner = () =>{

    useEffect(() => {
        new Audio(winnerSound).play();
    })

    const lang = localStorage.getItem('lang');
    return (
        <div>
            {/* =================================log============================= */}
            <div className="quizWinner">
                <img src={winnerImg} className="img-fluid" alt="Logo"/>
            </div>
            {/* =================================end_logo========================= */}
            <div className="text-center p-5">
                <h1>{lang === 'english' ? 'Congratulations' : 'অভিনন্দন'}</h1>
                <h5>{lang === 'english' ? 'You are the winner' : 'আপনি বিজয়ী'}</h5>
                <h6 className="text-athens-gray">{lang === 'english' ? 'Your prize will be reached to your mobile number within one week.' : 'আপনার পুরষ্কারটি এক সপ্তাহের মধ্যে আপনার মোবাইল নাম্বারে পৌঁছে যাবে।'}</h6>
                <Link to={'/'} className="btn btn-block btn-md btn-success"><b>{lang === 'HomePage' ? 'Home' : 'হোমপেজে যান'}</b></Link>

            </div>
        </div>
    );
}

export default Winner;